<template>
  <div>
    <p class="pointer text-light text-underline font-size-4" @click="createPDF">
      Télécharger le PDF
    </p>
    <div id="pdf-div">
      <div
        class="ml-5 mb-5"
        v-for="(chapter, id) in htmlDataCheatsheets"
        :key="id"
      >
        <p :class="'color-chapter-' + id" class="font-size-3 font-bold mb-3">
          {{ chapter.name.toUpperCase() }}
        </p>
        <p :class="'color-chapter-' + id" class="font-size-3 mb-2">
          Le code est partout
        </p>
        <p class="text-dark font-size-4 mb-2">La technologie...</p>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import splitText from "split-text";
import { fontBold, fontNormal } from "@/plugins/pdfFonts";

export default {
  name: "pdf-generator",
  props: {
    partsInfo: Object,
  },
  data() {
    return {
      chapters: this.$common.getSummary().chapters,

      doc: null,

      htmlDataCheatsheets: {},

      verticalPosition: null,
      currentPage: null,
      padding: {
        sm: 6,
        md: 10,
        lg: 15,
        xl: 20,
      },

      fontSize: {
        sm: 13,
        md: 14,
        lg: 16,
      },
    };
  },
  methods: {
    checkIfNewPage() {
      const heightPage = 280;
      if (Math.floor(this.verticalPosition / heightPage) > 0) {
        this.doc.addPage();
        this.verticalPosition = this.padding.xl;
      }
    },
    addFontToDoc() {
      this.doc.addFileToVFS("Poppins-Bold-normal.ttf", fontBold);
      this.doc.addFont("Poppins-Bold-normal.ttf", "Poppins-Bold", "normal");
      this.doc.addFileToVFS("Poppins-Medium-normal.ttf", fontNormal);
      this.doc.addFont("Poppins-Medium-normal.ttf", "Poppins-Medium", "normal");
    },
    addText(text, params = {}) {
      if (params.bold) this.doc.setFont("Poppins-Bold");
      else this.doc.setFont("Poppins-Medium");

      if (params.color && params.color.length)
        this.doc.setTextColor(params.color);
      else this.doc.setTextColor("#2b3345");

      if (params.fontSize && params.fontSize.length)
        this.doc.setFontSize(this.fontSize[params.fontSize]);
      else this.doc.setFontSize(this.fontSize["sm"]);

      if (params.isMultiline) {
        this.doc.setFontSize(this.fontSize["sm"]);

        var lines = text.split("\n");

        var splitStr = [];
        for (var line of lines) {
          splitStr = splitStr.concat(splitText(line, 65));
        }

        for (var str of splitStr) {
          this.doc.text(str, this.padding.xl, this.verticalPosition);
          this.verticalPosition += this.padding["sm"];
          this.checkIfNewPage();
        }
      } else {
        if (params.link && params.link.length) {
          var strLink =
            params.link.length > 65
              ? params.link.substring(0, 62) + "..."
              : params.link;
          this.doc.textWithLink(
            strLink,
            this.padding.xl,
            this.verticalPosition,
            {
              url: params.link,
            }
          );
        } else {
          this.doc.text(text, this.padding.xl, this.verticalPosition);
        }
      }

      if (params.paddingBottom)
        this.verticalPosition += this.padding[params.paddingBottom];
      else this.verticalPosition += this.padding["sm"];

      this.checkIfNewPage();
    },
    createPDF() {
      this.verticalPosition = this.padding.md;
      this.currentPage = 0;
      let pdfName = "leprocess_cheatsheets";
      this.doc = new jsPDF();

      this.addFontToDoc();

      const imageRatio = 2.88;
      const imageW = 50;
      const imageH = imageW / imageRatio;
      const imageData = require("@/assets/images/leprocess_logo_dark.jpg");
      this.doc.addImage(
        imageData,
        "JPEG",
        this.padding.md,
        this.verticalPosition,
        imageW,
        imageH
      );
      this.verticalPosition += imageH;
      this.verticalPosition += this.padding.lg;

      for (var chapter of this.chapters) {
        var hasUnlockCheatSheets = false;

        for (var part of chapter.parts) {
          if (this.partsInfo.hasOwnProperty(part.id)) {
            hasUnlockCheatSheets = true;
            break;
          }
        }

        if (!hasUnlockCheatSheets) continue;

        this.addText(chapter.name.toUpperCase(), {
          bold: true,
          color: chapter.color,
          fontSize: "lg",
          paddingBottom: "lg",
        });

        for (var part of chapter.parts) {
          if (this.partsInfo.hasOwnProperty(part.id)) {
            this.addText(part.name, {
              color: chapter.color,
              fontSize: "lg",
              paddingBottom: "md",
            });
            this.addText(this.partsInfo[part.id].cheatsheet, {
              isMultiline: true,
              paddingBottom: this.partsInfo[part.id].links.length ? "sm" : "lg",
            });

            for (var i = 0; i < this.partsInfo[part.id].links.length; i++) {
              this.addText(this.partsInfo[part.id].links[i], {
                link: this.partsInfo[part.id].links[i],
                color: "#0000FF",
                paddingBottom: i== this.partsInfo[part.id].links.length-1 ? "lg" : "sm",
              });
            }
          }
        }
      }

      this.doc.save(pdfName + ".pdf");

      this.$common.showSnackbar(
        "Le PDF ne contient que les cheat sheets que vous avez débloquées"
      );
    },
  },
  created: function () {},
  mounted: function () {},
};
</script>
