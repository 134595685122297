import Axios from "axios";
import router from '../router';
import { auth } from './firebase'

const isDev = (process.env.NODE_ENV == "development");
const APIBaseURL = isDev ? "http://localhost:5001/le-process/europe-west1/app" : "https://europe-west1-le-process.cloudfunctions.net/app";

const adapter = Axios.create({
    baseURL: APIBaseURL,
    headers: {
        "Content-Type": "application/json",
        "authorization": "4b449d3d-7e6d-4c0e-aa37-da8134d2536c",
    }
});

adapter.interceptors.request.use(async (config) => {
    config.headers.token = auth.currentUser ? await auth.currentUser.getIdToken(true) : "";
    return config;
}, function (error) {
    return Promise.reject(error);
});

function eachRecursive(obj) {
    for (var k in obj) {
        if (typeof obj[k] == "object" && obj[k] !== null)
            eachRecursive(obj[k]);
        else
            if (obj[k] == null) obj[k] = "";
    }
}

export default {
    install(Vue, options) {
        adapter.interceptors.response.use((response) => {
            eachRecursive(response.data);
            return response
        }, async function (error) {
            if (error.response.status === 404 || error.response.status === 500) router.push("/");

            return Promise.reject(error);
        });


        var api = {
            users: {
                getUser() { return adapter.get("/users") },
            },
            parts: {
                getInfo(id) { return adapter.get("/parts/" + id + "/info") },
                getMultiInfo(ids) { return adapter.put("/parts/multi-info", { ids: ids }) },
            },
            paymentsPaypal: {
                createOrder(body) { return adapter.post("/payments/paypal/create-order", body) },
                captureOrder(orderID) { return adapter.post("/payments/paypal/capture-order/" + orderID) },
            },
            paymentsSplitit: {
                createPayment(body) { return adapter.post("/payments/splitit/create-payment", body) },
            },
            paymentsPartially: {
                createPayment(body) { return adapter.post("/payments/partially/create-payment", body) },
                getStatus(trUID) { return adapter.get("/payments/partially/" + trUID + "/status") },
            },
            discounts: {
                getRentree2021Count() { return adapter.get("/discounts/rentree2021/count") },
            },
        }
        Vue.prototype.$api = api;
    }
};