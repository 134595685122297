<template>
  <modal name="login" width="320px" height="auto">
    <div class="position-relative">
      <div class="row my-5 justify-content-center">
        <div class="col-10">
          <p class="text-center text-dark font-bold font-size-3 mb-4">
            Se connecter
          </p>

          <p class="text-dark mb-1">Email</p>

          <input
            class="w-100 p-2 mb-4"
            v-model="email"
            v-on:keyup.enter="login"
            type="email"
          />

          <p class="text-dark mb-1">Mot de passe</p>

          <input
            class="w-100 p-2 mb-5"
            v-model="password"
            v-on:keyup.enter="login"
            type="password"
          />

          <button
            type="button"
            class="btn w-100 btn-lg font-bold btn-pink font-size-4 mb-4"
            @click="login"
          >
            Se connecter
          </button>

          <button
            type="button"
            class="
              btn
              w-100
              btn-lg
              font-bold
              btn-outline-pink
              block
              font-size-4
            "
            @click="goToPayment()"
          >
            Rejoindre
          </button>

          <p
            class="text-center text-danger font-size-6 my-2"
            :style="{ visibility: showError ? 'visible' : 'hidden' }"
          >
            Mot de passe ou email invalide
          </p>

          <p class="text-center text-dark font-size-6 line-height-sm my-2">
            Mot de passe oublié ?<br /><a
              class="text-underline text-dark"
              href="mailto:contact@freres-codeurs.com"
              >contact@freres-codeurs.com</a
            >
          </p>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
export default {
  name: "login-modal",
  props: {},
  data() {
    return {
      email: "",
      password: "",
      showError: false,
    };
  },
  methods: {
    goToPayment() {
      this.$router.push("/payment");
      this.$modal.hide("login");
    },
    async login() {
      if (!this.email.length || !this.password.length) return;

      this.$common.showPageLoader();
      this.showError = false;
      try {
        await this.$authentification.login(this.email, this.password);
      } catch (e) {
        this.showError = true;
        this.$common.hidePageLoader();
      }
    },
  },
  created: function () {
    this.$eventHub.$on("showLoginModal", () => {
      this.$modal.show("login");
    });
  },
  mounted: function () {},
  destroyed() {
    this.$eventHub.$off("showLoginModal");
  },
};
</script>
