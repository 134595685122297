<template>
  <img
    :id="id"
    :src="image"
    class="position-absolute bg-element z-index-1"
    :width="width"
    :style="
      'top:' +
      (this.initialTop + this.scrollOffsetY) +
      'px; left:' +
      this.initialLeft +
      'px;'
    "
  />
</template>

<script>
export default {
  name: "background-element",
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    image: String,
    width: Number,
    horizontalRange: Number,
    verticalRange: Number,
    initialTop: Number,
    initialLeft: Number,
    speed: {
      type: Number,
      default: 2500,
    },
    scrollCoeff: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      id: null,
      translateY: 0,
      translateX: 0,
      timeOut: null,
      scrollOffsetY: 0,
    };
  },
  methods: {
    startMouvement() {
      if (this.timeOut != null) clearTimeout(this.timeOut);

      var newTranslateY =
        this.translateY +
        this.$common.getRandomBetween(-this.verticalRange, this.verticalRange);
      if (newTranslateY > this.verticalRange)
        newTranslateY = this.verticalRange;
      if (newTranslateY < -this.verticalRange)
        newTranslateY = this.verticalRange;
      this.translateY = newTranslateY;

      var newTranslateX =
        this.translateX +
        this.$common.getRandomBetween(
          -this.horizontalRange,
          this.horizontalRange
        );
      if (newTranslateX > this.verticalRange)
        newTranslateX = this.verticalRange;
      if (newTranslateX < -this.verticalRange)
        newTranslateX = this.verticalRange;
      this.translateX = newTranslateX;

      this.div.style["transform"] =
        "translate(" + this.translateX + "px, " + this.translateY + "px)";

      this.timeOut = setTimeout(() => {
        this.startMouvement();
      }, this.speed);
    },
    handleScroll(event) {
      this.scrollOffsetY = -window.scrollY / this.scrollCoeff;
      // this.div.style.top = this.initialTop + this.scrollOffsetY + "px";
    },
    setStyle() {
      var transition =
        "top 500ms ease-out, transform " +
        this.speed +
        "ms linear, opacity 500ms linear";

      if (!this.visible) transition += ", visibility 0s 500ms";

      this.div.style["transition"] = transition;

      if (!this.visible) {
        this.div.style["visibility"] = "hidden";
        this.div.style["opacity"] = "0";
      } else {
        this.div.style["visibility"] = "visible";
        this.div.style["opacity"] = "1";
      }
    },
  },
  created: function () {
    this.id = "_" + Math.random().toString(36).substr(2, 9);
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted: function () {
    this.div = document.getElementById(this.id);

    this.setStyle();

    this.startMouvement();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    visible: function (newVal, oldVal) {
      this.setStyle();
    },
  },
};
</script>
