import HomeMenu from "../components/HomeMenu";
import FooterPage from "../components/FooterPage";
import MenuObject from "../components/MenuObject";
import Globe from "../components/Globe";
import GlobeTitle from "../components/GlobeTitle";
import BackgroundElement from "../components/BackgroundElement";
import LoginModal from "../components/LoginModal";
import ChapterModal from "../components/ChapterModal";
import ProgressBar from "../components/ProgressBar";
import CheatSheet from "../components/CheatSheet";
import CheatSheetModal from "../components/CheatSheetModal";
import PageLoader from "../components/PageLoader";
import PDFGenerator from "../components/PDFGenerator";

export default {
    install(Vue) {
        Vue.component(HomeMenu.name, HomeMenu);
        Vue.component(FooterPage.name, FooterPage);
        Vue.component(MenuObject.name, MenuObject);
        Vue.component(Globe.name, Globe);
        Vue.component(GlobeTitle.name, GlobeTitle);
        Vue.component(BackgroundElement.name, BackgroundElement);
        Vue.component(LoginModal.name, LoginModal);
        Vue.component(ChapterModal.name, ChapterModal);
        Vue.component(ProgressBar.name, ProgressBar);
        Vue.component(CheatSheet.name, CheatSheet);
        Vue.component(CheatSheetModal.name, CheatSheetModal);
        Vue.component(PageLoader.name, PageLoader);
        Vue.component(PDFGenerator.name, PDFGenerator);
    }
};