<template>
  <div class="loader-page" v-if="show">
    <div class="loader-background"></div>
    <div
      class="centered-axis-xy text-center"
      style="display: flex; flex-direction: column; align-items: center"
    >
      <p
        class="text-white font-size-4 mb-3"
        v-if="text.length"
        style="white-space: pre-wrap"
      >
        {{ text }}
      </p>
      <div id="loader" v-if="enableImage">
        <div class="planet-top"></div>
        <div class="planet-bottom"></div>
        <div class="ring"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-loader",
  props: {
    show: Boolean,
    enableImage: Boolean,
    text: String,
  },
  data() {
    return {};
  },
  methods: {},
  created: function () {},
  mounted: function () {},
};
</script>
