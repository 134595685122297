<template>
  <div
    class="position-relative progress text-center"
    :style="
      'border-color:' +
      complementaryColor +
      ';background-color:' +
      complementaryColor +
      ';'
    "
  >
    <div
      class="progress-filled position-absolute h-100"
      style="top: 0px; right: 0px"
      :style="'width:' + (100 - percent).toFixed(0) + '%;'"
    ></div>

    <p
      class="font-size-4 font-bold line-height-xs centered-axis-xy"
      :style="'color:' + complementaryColor + ';'"
    >
      {{ percent.toFixed(0) }}%
    </p>
  </div>
</template>

<script>
export default {
  name: "progress-bar",
  props: {
    color: String,
    percent: Number,
  },
  data() {
    return {
      complementaryColor: null,
    };
  },
  methods: {
    invertColor(hex) {
      if (hex.indexOf("#") === 0) {
        hex = hex.slice(1);
      }
      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
      }
      // invert color components
      var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
        g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
        b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
      // pad each with zeros and return
      return "#" + this.padZero(r) + this.padZero(g) + this.padZero(b);
    },

    padZero(str, len) {
      len = len || 2;
      var zeros = new Array(len).join("0");
      return (zeros + str).slice(-len);
    },
  },
  created: function () {
    this.complementaryColor = this.invertColor(this.color);
  },
  mounted: function () {},
};
</script>
