<template>
  <div :class="'bg-chapter-' + chapterId">
    <div class="row my-4 justify-content-center">
      <div class="col-11 mb-3" :class="{ 'px-md-1': smallPadding }">
        <p class="text-white font-bold font-size-3">{{ title }}</p>
      </div>
      <div class="col-11 mb-2" :class="{ 'px-md-1': smallPadding }">
        <p class="text-white font-size-4" style="white-space: pre-wrap">
          {{ text }}
        </p>
      </div>
      <div
        v-if="links.length"
        class="col-11 mt-3 mb-2"
        :class="{ 'px-md-1': smallPadding }"
      >
        <a
          v-for="(link, index) in links"
          :key="index"
          :href="link"
          target="_blank"
        >
          <p
            class="text-white text-underline font-size-4"
            style="overflow: hidden; text-overflow: ellipsis"
          >
            {{ link }}
          </p></a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "cheat-sheet",
  props: {
    chapterId: String,
    title: String,
    text: String,
    links: Array,

    smallPadding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
};
</script>
