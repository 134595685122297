<template>
  <div
    class="globe-title centered-axis-xy"
    :id="'globe-title-' + indexTitle"
    :style="
      'visibility: ' + (renderReady ? 'visible' : 'hidden') + ';z-index:10;'
    "
  >
    <div class="position-relative px-4 py-3">
      <div class="pointer" @click="openChapterModal()" @click.stop.prevent>
        <p class="font-bold">{{ chapter.name }}</p>
        <p class="font-size-6 font-italic">
          {{ chapter.parts.length }} contenus ({{
            $common.displayDuration(chapter.duration)
          }})
        </p>
        <p
          v-for="(part, index) in chapter.parts.slice(0, 5)"
          :key="index"
          class="font-size-6 line-height-sm"
        >
          {{ part.name }} ({{ $common.displayDuration(part.duration) }})
        </p>
        <p class="font-size-6">...</p>
        <p class="font-size-7 line-height-xs text-right">
          CLIQUEZ POUR VOIR PLUS
        </p>
      </div>

      <div
        class="position-absolute"
        :id="'globe-connector-' + indexTitle"
        :height="divConnectorHeight"
        :width="divConnectorWidth"
        :style="'min-height: 1px; stroke: ' + chapter.color + ';'"
      >
        <svg
          :height="divConnectorHeight"
          :width="divConnectorWidth"
          style="min-height: 1px"
        >
          <line
            :x1="xStart"
            :y1="yStart"
            v-if="linesNumberConnector == 1"
            :x2="xEnd - radiusCircle * 2"
            :y2="yEnd"
          />
          <line
            :x1="xStart"
            :y1="yStart"
            v-if="linesNumberConnector == 2"
            :x2="x2"
            :y2="y2"
          />
          <line
            :x1="x2"
            :y1="y2"
            v-if="linesNumberConnector == 2"
            :x2="xEnd - radiusCircle * 2 + strokeWidthCircle"
            :y2="yEnd - radiusCircle * 2 + strokeWidthCircle"
          />
          <circle
            :cx="divConnectorWidth - radiusCircle"
            :cy="
              linesNumberConnector > 1
                ? divConnectorHeight - radiusCircle
                : divConnectorHeight
            "
            :r="radiusCircle"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "globe-title",
  props: {
    chapter: Object,
    indexTitle: Number,
    angle: Number,
    canvasSize: Number,
    xOffset: {
      type: Number,
      default: 0,
    },
    yOffset: {
      type: Number,
      default: 0,
    },
    linesNumberConnector: {
      type: Number,
      default: 2,
    },
    hasVerticalConnector: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      renderReady: false,

      div: null,
      verticalSide: null,
      horizontalSide: null,
      distance: null,

      coordX: null,
      coordY: null,

      radiusCircle: 10,
      strokeWidthCircle: 4,
      divConnectorWidth: null,
      divConnectorHeight: null,
      xStart: null,
      yStart: null,
      xEnd: null,
      yEnd: null,
      x2: null,
      y2: null,
    };
  },
  methods: {
    openChapterModal() {
      this.$common.showChapterModal(this.chapter.id);
    },
    computeCoordonate() {
      this.coordX = this.distance * Math.cos(this.angle) + this.xOffset;
      this.coordY = -this.distance * Math.sin(this.angle) + this.yOffset;

      if (this.coordX < 0) this.horizontalSide = "left";
      else this.horizontalSide = "right";
      if (this.coordY > 0) this.verticalSide = "bottom";
      else this.verticalSide = "top";

      this.div.style["transform"] =
        "translate(" +
        (this.coordX - this.div.clientWidth / 2) +
        "px, " +
        (this.coordY - this.div.clientHeight / 2) +
        "px)";
    },
    computeConnectorDiv() {
      var deltaCoordY = Math.abs(this.coordY / this.div.clientWidth);
      var deltaCoordX = Math.abs(this.coordX / this.div.clientWidth);

      const coeffHeight =
        this.$common.getRandomBetween(60, 65) / 100 - deltaCoordX / 20;
      const coeffWidth =
        this.$common.getRandomBetween(60, 65) / 100 + deltaCoordY / 10;
      var divConnector = document.getElementById(
        "globe-connector-" + this.indexTitle
      );

      this.divConnectorWidth =
        Math.abs(this.distance * coeffWidth * Math.cos(this.angle)) -
        this.div.clientWidth / 2 +
        Math.abs(this.xOffset);
      this.divConnectorHeight = Math.abs(
        this.distance * coeffHeight * Math.cos(Math.PI / 2 - this.angle)
      ) + (this.yOffset * 1.6);

      var scaleX = 1;
      var scaleY = 1;

      if (this.hasVerticalConnector) {
        this.divConnectorWidth = this.radiusCircle * 2;
        divConnector.style.left =
          this.div.clientWidth / 2 - this.divConnectorWidth / 2 + "px";
        divConnector.style.bottom = this.div.clientHeight + "px";
        scaleY = -1;
        console.log(this.horizontalSide, this.verticalSide);
      } else {
        if (this.horizontalSide == "left") {
          divConnector.style.right = -this.divConnectorWidth + "px";
        } else {
          divConnector.style.left = -this.divConnectorWidth + "px";
          scaleX = -1;
        }

        if (this.verticalSide == "top") {
          divConnector.style.top = this.div.clientHeight / 2 + "px";
        } else {
          divConnector.style.bottom = this.div.clientHeight / 2 + "px";
          scaleY = -1;
        }
      }

      divConnector.style.transform = "scale(" + scaleX + "," + scaleY + ")";
    },
    setConnectorLines() {
      this.xStart = 0;
      this.yStart = 0;
      this.xEnd = this.divConnectorWidth;
      this.yEnd = this.divConnectorHeight;

      if (this.hasVerticalConnector) {
        this.xStart = this.radiusCircle;
        this.xEnd = this.xStart + this.radiusCircle * 2;
        this.yEnd = this.divConnectorHeight - this.radiusCircle;
      } else {
        this.x2 =
          this.divConnectorWidth / (this.$common.getRandomBetween(25, 35) / 10);
      }
      this.y2 = this.yStart;
    },
  },
  created: function () {},
  mounted: function () {
    this.div = document.getElementById("globe-title-" + this.indexTitle);
    this.div.style["border-color"] = this.chapter.color;

    this.distance = this.canvasSize / 1.8;

    this.computeCoordonate();

    this.computeConnectorDiv();
    this.setConnectorLines();

    this.renderReady = true;
  },
};
</script>
