import Vue from 'vue'
import Router from 'vue-router'
import { analytics } from './plugins/firebase'
import { version } from "../package.json";

const Home = () =>
    import('./views/Home.vue');
const LegalPage = () =>
    import('./views/LegalPage.vue');
const Dashboard = () =>
    import('./views/Dashboard.vue');
const Part = () =>
    import('./views/Part.vue');
const Payment = () =>
    import('./views/Payment.vue');
    const PaymentDone = () =>
    import('./views/PaymentDone.vue');

// to avoid NavigationDuplicated
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(Router)

const restritedConnectedPage = ["Dashboard", "Part"];
const restritedUnconnectedPage = ["Payment", "PaymentDone"];
// const restritedConnectedPage = [];

const router = new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'Home',
            components: {
                default: Home,
            }
        },
        {
            path: '/terms',
            name: 'Terms',
            components: {
                default: LegalPage,
            }
        },
        {
            path: '/privacy',
            name: 'Privacy',
            components: {
                default: LegalPage,
            }
        },
        {
            path: '/legal',
            name: 'Legal',
            components: {
                default: LegalPage,
            }
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            components: {
                default: Dashboard,
            }
        },
        {
            path: '/payment',
            name: 'Payment',
            components: {
                default: Payment,
            }
        },
        {
            path: '/payment-done/:token',
            name: 'PaymentDone',
            components: {
                default: PaymentDone,
            }
        },
        {
            path: '/part/:id',
            name: 'Part',
            components: {
                default: Part,
            }
        },
        { path: '*', redirect: '/' } //default Home
    ],
});

router.beforeEach((to, from, next) => {
    var routeName = to.name;

    if (Vue.prototype.$authentification.user == null && restritedConnectedPage.indexOf(routeName) != -1) {
        router.push("/");
    }
    if (Vue.prototype.$authentification.user != null && restritedUnconnectedPage.indexOf(routeName) != -1) {
        router.push("/");
    }
    analytics.setCurrentScreen(routeName);
    analytics.logEvent("page_view");
    analytics.logEvent("screen_view", {
        app_name: "web",
        screen_name: routeName,
        app_version: version
    });
    next();
})

export default router