<template>
  <div>
    <div class="d-md-none menu-element-mobile" @click="$emit('nav-click')">
      <img
        :src="image"
        :alt="imageAlt"
        class="centered-axis-xy"
        @click="$emit('click')"
      />
    </div>
    <div
      class="d-none d-md-flex menu-element row m-0 bg-dark pointer closed"
      style="overflow: hidden"
      @click="$emit('nav-click')"
    >
      <div class="col-auto p-0">
        <img :src="image" :alt="imageAlt" @click="$emit('click')" />
      </div>
      <div class="col pl-2 pr-0" style="overflow: hidden">
        <p class="text-white font-bold font-size-4">{{ label }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "menu-object",
  props: {
    label: String,
    image: String,
    imageAlt: String,
  },
  data() {
    return {};
  },
  methods: {},
  created: function () {},
  mounted: function () {},
};
</script>
