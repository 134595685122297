<template>
  <modal
    name="cheatsheet"
    :classes="
      'rounded ' + (part ? 'bg-chapter-' + part.chapter.id : '')
    "
    height="auto"
    :scrollable="true"
  >
    <div class="modal-body custom-scroll">
      <cheat-sheet
        v-if="part"
        :chapter-id="part.chapter.id"
        :text="text"
        :title="title"
        :links="links"
      >
      </cheat-sheet>
    </div>
  </modal>
</template>
<script>
export default {
  name: "cheat-sheet-modal",
  data() {
    return {
      part: null,
      title: "",
      text: "",
      links: [],
    };
  },
  methods: {},
  created: function () {
    this.$eventHub.$on("showCheatSheetModal", (partID, text, links) => {
      this.part = this.$common.getPartInformation(partID);
      this.title = this.part.name;
      this.text = text;
      this.links = links;
      this.$modal.show("cheatsheet");
    });
  },
  mounted: function () {},
  destroyed() {
    this.$eventHub.$off("showCheatSheetModal");
  },
};
</script>
