import router from '../router';

const jwt = require('jsonwebtoken');

const jsonld =
    require("@/assets/meta/jsonld.json");
const meta =
    require("@/assets/meta/meta.json");
const summary =
    require("@/assets/others/summary.json");

export default {
    install(Vue) {
        var common = {
            getScreenSize() {
                var windowWidth = window.innerWidth;

                if (windowWidth < 576) return "xs";
                else if (windowWidth < 768) return "sm";
                else if (windowWidth < 992) return "md";
                else if (windowWidth < 1200) return "lg"
                else return "xl";
            },
            getBrowser() {
                var agent = window.navigator.userAgent.toLowerCase();
                switch (true) {
                    case agent.indexOf("edge") > -1: return "edge";
                    case agent.indexOf("edg") > -1: return "chromium based edge (dev or canary)";
                    case agent.indexOf("opr") > -1 && !!window.opr: return "opera";
                    case agent.indexOf("chrome") > -1 && !!window.chrome: return "chrome";
                    case agent.indexOf("trident") > -1: return "ie";
                    case agent.indexOf("firefox") > -1: return "firefox";
                    case agent.indexOf("safari") > -1: return "safari";
                    default: return "other";
                }
            },
            sleep(milliseconds) {
                var start = new Date().getTime();
                for (var i = 0; i < 1e7; i++) {
                    if ((new Date().getTime() - start) > milliseconds) {
                        break;
                    }
                }
            },
            isPrerendering() {
                return (typeof window.__PRERENDER_INJECTED != "undefined" && window.__PRERENDER_INJECTED.isPrerender == true);
            },
            getPageMeta() {
                return meta;
            },
            getJsonLd() {
                return jsonld;
            },
            getSummary() {
                return summary;
            },
            getChapterInformation(chapterID) {
                var chapterIndex = summary.chapters.findIndex(c => c.id == chapterID);
                if (chapterIndex == -1) return;

                return summary.chapters[chapterIndex];
            },
            getPartInformation(partID) {
                for (var chapter of summary.chapters) {
                    var partIndex = chapter.parts.findIndex(p => p.id == partID);
                    if (partIndex != -1) {
                        var result = chapter.parts[partIndex];
                        result.chapter = chapter;
                        return result;
                    }
                }
                return;
            },
            checkIfPartIDExist(partID) {
                for (var chapter of summary.chapters) {
                    for (var part of chapter.parts) {
                        if (part.id == partID) return true;
                    }
                }
                return false;
            },
            getRandomBetween(min, max) {
                return Math.floor(Math.random() * (max - min + 1) + min);;
            },
            displayDuration(seconds) {
                var momentDuration = Vue.prototype.$moment.duration(seconds, "seconds");
                var hasSingleHours = momentDuration.get('hours') == 1;
                var hasMultipleHours = momentDuration.get('hours') > 1;
                var hasMultipleMinutes = momentDuration.get('minutes') > 1;

                var format;

                if (hasMultipleHours) format = 'HH:mm:ss';
                else if (hasSingleHours) format = 'H:mm:ss';
                else if (hasMultipleMinutes) format = 'mm:ss';
                else format = 'm:ss';

                return Vue.prototype.$moment.utc(momentDuration.as('milliseconds')).format(format);
            },
            getGlobeAngles() {
                var angles = [
                    (2 * Math.PI) / 3 + 0.35,
                    (5 * Math.PI) / 6 + 0.2,
                    Math.PI,
                    (7 * Math.PI) / 6 - 0.2,
                    (4 * Math.PI) / 3 - 0.35,
                    Math.PI / 3 - 0.35,
                    Math.PI / 6 - 0.2,
                    0,
                    (11 * Math.PI) / 6 + 0.2,
                    (5 * Math.PI) / 3 + 0.35,
                    (1.5 * Math.PI),
                ];

                return angles;
            },
            showLoginModal() {
                Vue.prototype.$eventHub.$emit("showLoginModal");
            },
            showChapterModal(chapterID) {
                Vue.prototype.$eventHub.$emit("showChapterModal", chapterID);
            },
            showCheatsheetModal(partID, text, links) {
                Vue.prototype.$eventHub.$emit("showCheatSheetModal", partID, text, links);
            },
            showPageLoader(enableImage = true, text = "") {
                Vue.prototype.$eventHub.$emit("showPageLoader", enableImage, text);
            },
            hidePageLoader() {
                Vue.prototype.$eventHub.$emit("hidePageLoader");
            },
            showSnackbar(message, buttonText, onTap) {
                Vue.prototype.$snack.show({ text: message, button: buttonText, action: onTap});
            },
            generateFrontPaymentToken() {
                return jwt.sign({ exp: Math.floor(Date.now() / 1000) + (1 * 60), }, 'payment-token');//exp in 10 minutes
            },
            checkFrontPaymentToken(token) {
                try {
                    jwt.verify(token, 'payment-token');
                } catch (e) {
                    return false;
                }
                return true;
            },
            goToLastPart() {
                var lastPartId = Vue.prototype.$database.lastPartId;
                if (lastPartId == null) {
                    var firstPartId = this.getSummary().chapters[0].parts[0].id;
                    router.push("/part/" + firstPartId);
                } else {
                    router.push("/part/" + lastPartId);
                }
            }
        };


        Vue.prototype.$common = common;
    }
}