import Vue from 'vue'
import App from './App.vue'
import router from './router'
import kit from "./plugins/kit";
import { auth, analytics } from './plugins/firebase'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

Vue.use(kit);

Vue.prototype.$eventHub = new Vue();

Vue.component('v-style', {
    render: function (createElement) {
        return createElement('style', this.$slots.default)
    }
});

let app
auth.onAuthStateChanged((user) => {
    if (user) {
        analytics.setUserId(user.uid);
        Vue.prototype.$authentification.user = user;
        if (router.currentRoute.name != null) location.reload();
    } else {
        Vue.prototype.$authentification.user = null;
        if (router.currentRoute.name != null) location.reload();
    }

    if (!app) {
        app = new Vue({
            router,
            render: h => h(App)
        }).$mount('#app')
    }
})