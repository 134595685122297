<template>
  <div id="app" class="position-relative">
    <div id="background-image"></div>
    <v-style>
      {{ addChapterColorStyle() }}
    </v-style>

    <home-menu v-if="!$common.isPrerendering()"></home-menu>
    <transition name="slide-left">
      <router-view
        v-show="!$common.isPrerendering()"
        :key="$route.fullPath"
        v-if="allowRouter"
      ></router-view>
    </transition>

    <login-modal
      v-if="$authentification.user == null && !$common.isPrerendering()"
    ></login-modal>

    <chapter-modal v-if="!$common.isPrerendering()"></chapter-modal>
    <cheat-sheet-modal
      v-if="$authentification.user != null && !$common.isPrerendering()"
    ></cheat-sheet-modal>

    <footer-page v-if="allowRouter && !$common.isPrerendering()"></footer-page>

    <page-loader
      v-if="!$common.isPrerendering()"
      :show="showPageLoader && !paypalLoaderDisplay"
      :enable-image="enableImagePageLoader"
      :text="textPageLoader"
    ></page-loader>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      allowRouter: false,
      summary: this.$common.getSummary(),

      paypalLoaderDisplay: false,

      showPageLoader: false,
      enableImagePageLoader: true,
      textPageLoader: "",
    };
  },
  methods: {
    addChapterColorStyle() {
      var strStyle = "";
      for (var chapter of this.summary.chapters) {
        strStyle +=
          ".color-chapter-" + chapter.id + "{color:" + chapter.color + ";}\n";
        strStyle +=
          ".bg-chapter-" + chapter.id + "{background:" + chapter.color + ";}\n";
        strStyle +=
          ".btn-outline-chapter-" +
          chapter.id +
          "{background-color: transparent;color:" +
          chapter.color +
          ";border-color:" +
          chapter.color +
          ";}\n";
        strStyle +=
          ".btn-chapter-" +
          chapter.id +
          "{color: white; background-color: " +
          chapter.color +
          ";}\n";
      }
      return strStyle;
    },
    setPageLoaderListener() {
      this.$eventHub.$on("showPageLoader", (enableImage, text) => {
        this.enableImagePageLoader = enableImage;
        this.textPageLoader = text;
        this.showPageLoader = true;
      });
      this.$eventHub.$on("hidePageLoader", () => {
        this.showPageLoader = false;
      });
    },
    setBodyMutationObserver() {
      var dom_observer = new MutationObserver((mutations) => {
        for (var mutation of mutations) {
          if (mutation.addedNodes && mutation.addedNodes.length) {
            var indexPaypal = Array.from(mutation.addedNodes).findIndex((e) =>
              e.id ? e.id.startsWith("paypal-overlay-") : false
            );
            if (indexPaypal != -1) this.paypalLoaderDisplay = true;
          }
          if (mutation.removedNodes && mutation.removedNodes.length) {
            var indexPaypal = Array.from(mutation.removedNodes).findIndex((e) =>
              e.id ? e.id.startsWith("paypal-overlay-") : false
            );
            if (indexPaypal != -1) this.paypalLoaderDisplay = false;
          }
        }
      });

      var config = { attributes: false, childList: true, characterData: false };
      dom_observer.observe(document.body, config);
    },
  },
  created: async function () {
    this.setPageLoaderListener();
    if (this.$authentification.user != null) {
      this.showPageLoader = true;
      this.enableImagePageLoader = true;

      // check if no payment defaulted
      var res = await this.$api.users.getUser();
      console.log(res.data);
      if (res.data.user.payment_defaulted) {
        window.location.hash = "#paymentdefaulted";
        return this.$authentification.logout();
      }

      // get user advancement
      await this.$database.initAdvancement(this.$authentification.user.uid);
      this.showPageLoader = false;
    }
    this.allowRouter = true;
  },
  mounted: function () {
    this.setBodyMutationObserver();
    if (window.location.hash == "#paymentdefaulted") {
      window.location.hash = "";
      this.$common.showSnackbar(
        "Votre compte est bloqué temporairement pour mise en défaut de paiement.",
        "Nous contacter",
        () => {
          window.location = "mailto:contact@freres-codeurs.com";
        }
      );
    }
  },
};
</script>
