import "@/assets/scss/all.scss";
import globalComponents from "./globalComponents";
import API from "@/plugins/api";
import common from "@/plugins/common";
import authentification from "@/plugins/authentification";
import database from "@/plugins/database";
import VueMeta from 'vue-meta';
import VueJsonLD from 'vue-jsonld';
import VueMoment from 'vue-moment';
import moment from 'moment';
import vmodal from 'vue-js-modal'
import VueYouTubeEmbed from 'vue-youtube-embed';
import vueVimeoPlayer from 'vue-vimeo-player';
import VueScrollTo from 'vue-scrollto';
import VueSnackbar from 'vue-snack';
import VueResize from 'vue-resize';

import 'vue-snack/dist/vue-snack.min.css';
import 'vue-resize/dist/vue-resize.css';

export default {
    install(Vue) {
        Vue.use(globalComponents);
        Vue.use(API);
        Vue.use(common);
        Vue.use(authentification);
        Vue.use(database);
        Vue.use(VueMeta);
        Vue.use(VueJsonLD);
        Vue.use(VueResize);
        Vue.use(VueMoment, { moment });
        Vue.use(vmodal);
        Vue.use(VueYouTubeEmbed);
        Vue.use(vueVimeoPlayer);
        Vue.use(VueScrollTo);
        Vue.use(VueSnackbar,  { position: 'bottom', time: 5000 });
    }
};