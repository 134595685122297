<template>
  <div
    class="w-100 pt-5 pb-3 text-center"
    :class="{ invisible : !show }"
    id="footer-page"
  >
    <p class="text-light font-size-6">
      <router-link class="text-light" to="/terms"
        >Conditions générales</router-link
      >
      <span> - </span>
      <router-link class="text-light" to="/privacy"
        >Politiques de confidentialité</router-link
      >
      <span> - </span>
      <router-link class="text-light" to="/legal">Mentions légales</router-link>
      <span class="ml-4">@2021</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "footer-page",
  data() {
    return {
      show: false,
    };
  },
  methods: {},
  created: function () {},
  mounted: function () {
    var interval = setInterval(() => {
      var rect = document.getElementById("footer-page").getBoundingClientRect();
      if (rect.top > 500) {
        this.show = true;
        clearInterval(interval)
      }
    }, 10);
  },
};
</script>
