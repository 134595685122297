<template>
  <modal
    name="chapter"
    :classes="'rounded ' + (chapter ? 'bg-chapter-' + chapter.id : '')"
    height="auto"
    :scrollable="true"
  >
    <div class="modal-body custom-scroll">
      <div class="row my-4 justify-content-center" v-if="chapter">
        <div class="col-11 mb-3">
          <p class="text-white font-bold font-size-3">{{ chapter.name }}</p>
        </div>
        <div
          class="col-11 mb-2"
          v-for="(part, index) in chapter.parts"
          :key="index"
        >
          <p
            class="text-white font-size-4"
            @click="goToPart(part.id)"
            :class="{
              pointer: $authentification.user != null,
              'font-bold': currentPartID == part.id,
            }"
          >
            {{ part.name }}
            <i v-if="isPartDone(part.id)" class="ml-2 fas fa-check-circle"></i>
          </p>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
export default {
  name: "chapter-modal",
  props: {},
  data() {
    return {
      chapter: null,

      currentPartID: "",
    };
  },
  methods: {
    getPartFromRoute() {
      if (this.$route.name != "Part") {
        this.currentPartID = "";
        return;
      }
      console.log(this.$route.params.id)
      this.currentPartID = this.$route.params.id;
    },
    goToPart(partID) {
      if (this.$authentification.user == null) return;

      this.$router.push("/part/" + partID);
      this.$modal.hide("chapter");
      this.chapter = null;
    },
    isPartDone(partID) {
      if (this.$authentification.user == null) return false;

      return (
        this.$database.advancement.hasOwnProperty(partID) &&
        this.$database.advancement[partID] == true
      );
    },
  },
  created: function () {
    this.$eventHub.$on("showChapterModal", (chapterID) => {
      this.getPartFromRoute();
      this.chapter = this.$common.getChapterInformation(chapterID);
      this.$modal.show("chapter");
    });
  },
  mounted: function () {},
  destroyed() {
    this.$eventHub.$off("showChapterModal");
  },
};
</script>
