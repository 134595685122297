<template>
  <div
    class="row align-items-center m-0 position-relative"
    id="container-globe"
    :class="!isRender ? 'hidden-fadeout' : 'visible-fadein'"
  >
    <background-element
      :visible="!globeOpened"
      :width="widthScreen * 0.025"
      :image="require('@/assets/images/home/illus_0.png')"
      :initial-top="heightScreen * 0.15"
      :initial-left="widthScreen * 0.3"
      :horizontal-range="10"
      :vertical-range="20"
    ></background-element>
    <background-element
      :visible="!globeOpened"
      :width="widthScreen * 0.045"
      :image="require('@/assets/images/home/illus_mouse.png')"
      :initial-top="heightScreen * 0.18"
      :initial-left="widthScreen * 0.65"
      :horizontal-range="10"
      :vertical-range="20"
    ></background-element>
    <background-element
      :visible="!globeOpened"
      :width="widthScreen * 0.09"
      :image="require('@/assets/images/home/illus_code.png')"
      :initial-top="heightScreen * 0.5"
      :initial-left="widthScreen * 0.2"
      :horizontal-range="10"
      :vertical-range="20"
    ></background-element>
    <background-element
      :visible="!globeOpened"
      :width="widthScreen * 0.065"
      :image="require('@/assets/images/home/illus_1.png')"
      :initial-top="heightScreen * 0.5"
      :initial-left="widthScreen * 0.65"
      :horizontal-range="10"
      :vertical-range="20"
    ></background-element>

    <div class="col-12 p-0">
      <div class="row justify-content-center m-0 z-index-2">
        <div
          class="p-0 position-relative"
          :class="
            !globeOpened
              ? 'col-9 col-md-8 col-lg-7 col-xl-5'
              : 'col-10 col-md-8 col-lg-8 col-xl-6'
          "
          id="col-globe-div"
        >
          <resize-observer @notify="handleResizeGlobe" />
          <div
            class="position-relative p-0 pointer"
            id="globe-div"
            :style="{
              width: idealGlobeWidth + 'px',
              transform: 'scale(' + scaleGlobe + ')',
            }"
            @click="globeOpened = !globeOpened"
          >
            <div
              v-if="showTitles"
              class="w-100 h-100 position-absolute"
              style="top: 0; left: 0"
            >
              <div class="position-relative w-100 h-100" v-if="divSize != null">
                <globe-title
                  v-for="(chapter, index) in summary.chapters"
                  :key="'titles_' + keyTitles + '_' + index"
                  :index-title="index"
                  :angle="anglesTitle[index]"
                  :lines-number-connector="linesNumberTitles[index]"
                  :x-offset="xOffsetTitles[index]"
                  :y-offset="yOffsetTitles[index]"
                  :has-vertical-connector="hasVerticalConnector[index]"
                  :canvas-size="divSize"
                  :chapter="chapter"
                ></globe-title>
              </div>
            </div>
            <div class="centered-axis-xy">
              <img
                :src="require('@/assets/images/leprocess_logo.png')"
                alt="Le process logo"
                :style="'width:' + divSize * 0.42 + 'px;'"
              />
            </div>
          </div>
          <div
            id="globe-text"
            class="col-12 text-center visible-fadein"
            :class="globeOpened ? 'dnone-fadeout' : ''"
            :style="'max-height:' + maxHeightText + 'px'"
          >
            <button
              class="btn btn btn-xl text-white bg-dark mb-3"
              v-if="$authentification.user != null"
              @click="$common.goToLastPart()"
            >
              REPRENDRE
            </button>

            <p class="font-size-3 font-bold text-dark mb-4">
              Tout ce qu'on ne dit PAS aux<br />futurs développeurs et aux
              débutants
            </p>
            <p class="badge badge-red ml-3 px-4 py-2 rounded mb-3">
              Nouveau module pour la rentrée 2021 !
            </p>
            <p class="font-size-4 text-light">
              Cliquez sur la sphère pour explorer
            </p>

            <button
              class="btn btn btn-xl text-white bg-dark mt-5"
              v-if="$authentification.user == null"
              @click="$eventHub.$emit('showInformations')"
            >
              REJOINDRE
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="position-absolute"
      style="bottom: 10px; left: 50%; transform: translateX(-50%)"
      :class="globeOpened || !showChevron ? 'hidden-fadeout' : 'visible-fadein'"
      id="icon-chevron"
    >
      <p class="pointer font-size-1 text-dark" @click="scrollToEndTopDiv">
        <i class="fas fa-chevron-down"></i>
      </p>
    </div>

    <video
      v-if="this.showVideo"
      id="videoSphere"
      type="video/webm"
      muted
      loop
      autoplay
      width="512"
      height="512"
      :src="require('@/assets/images/globe/sphere_texture.webm')"
      style="display: none"
    ></video>
  </div>
</template>

<script>
import * as THREE from "three";

export default {
  name: "globe",
  props: {},
  data() {
    return {
      idealGlobeWidth: 1000,
      scaleGlobe: null,
      widthScreen: 0,
      heightScreen: 0,

      globeRatio: 2,
      edgesSphere: 40,
      edgesRing: 90,
      maxRotation: 0.5,
      minRotation: -0.5,
      rotationSpeed: 0.003,
      ringOffsetStart: 1.6,
      ringOffsetEnd: 1.65,
      cameraZoom: 2.5,

      isRender: false,
      isRenderInterval: null,

      div: null,
      divSize: null,
      scene: null,
      camera: null,
      renderer: null,
      interaction: null,
      sphere: null,
      ring: null,

      showVideo: null,

      divText: null,
      maxHeightText: 150,

      rotationAngle: 0,

      globeOpened: false,
      showChevron: true,
      showTitles: false,
      keyTitles: 1,

      summary: this.$common.getSummary(),
      anglesTitle: this.$common.getGlobeAngles(),
      xOffsetTitles: [-60, 0, 0, 0, -60, 60, 0, 0, 0, 60, 0],
      yOffsetTitles: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -120],
      linesNumberTitles: [2, 2, 1, 2, 2, 2, 2, 1, 2, 2, 1],
      hasVerticalConnector: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
      ],
    };
  },
  methods: {
    handleResizeGlobe({ width, height }) {
      this.scaleGlobe = width / this.idealGlobeWidth;
    },
    checkIfRender() {
      if (
        this.scene == null ||
        !this.scene.children ||
        this.scene.children.length != 2
      )
        return;

      setTimeout(() => {
        this.isRender = true;
      }, 100);

      clearInterval(this.isRenderInterval);
    },
    getScreenSize() {
      this.heightScreen = window.innerHeight;
      this.widthScreen = window.innerWidth;
    },
    scrollToEndTopDiv() {
      var div = document.getElementById("container-globe");
      window.scroll({
        top: div.clientHeight,
        behavior: "smooth",
      });
    },
    computeShowChevron() {
      var iconChevron = document.getElementById("icon-chevron");
      if (iconChevron.offsetTop - window.scrollY < window.innerHeight / 1.2) {
        this.showChevron = false;
      } else {
        this.showChevron = true;
      }
    },
    create3DSphere() {
      var textureSphere;

      if (this.showVideo) {
        var video = document.getElementById("videoSphere");
        video.play();
        textureSphere = new THREE.VideoTexture(video);
      } else {
        textureSphere = new THREE.TextureLoader().load(
          require("@/assets/images/globe/sphere_image.png")
        );
      }

      var geometrySphere = new THREE.SphereGeometry(
        1,
        this.edgesSphere,
        this.edgesSphere
      );
      var materialSphere = new THREE.MeshBasicMaterial({
        map: textureSphere,
      });
      this.sphere = new THREE.Mesh(geometrySphere, materialSphere);
      this.scene.add(this.sphere);
    },
    create3DRing() {
      var geometryRing = new THREE.RingGeometry(
        this.ringOffsetStart,
        this.ringOffsetEnd,
        this.edgesRing
      );
      var materialRing = new THREE.MeshBasicMaterial({
        color: 0x2b3345,
      });
      this.ring = new THREE.Mesh(geometryRing, materialRing);
      this.ring.rotation.set(-Math.PI / 2, 0, 0);
      this.scene.add(this.ring);
    },
    createScene() {
      this.div = document.getElementById("globe-div");
      this.divSize = this.div.clientWidth;

      this.scene = new THREE.Scene();

      this.camera = new THREE.PerspectiveCamera(50, this.globeRatio);

      this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
      this.renderer.domElement.style["margin-left"] = "auto";
      this.renderer.domElement.style["margin-right"] = "auto";
      this.div.appendChild(this.renderer.domElement);

      this.setSceneParameters();
    },
    setSceneParameters() {
      this.renderer.setSize(this.divSize, this.divSize / this.globeRatio);

      this.camera.position.set(0, 0.15, this.cameraZoom);
      this.camera.lookAt(new THREE.Vector3(0, 0, 0));
      this.renderer.render(this.scene, this.camera);
    },
    setRingPerspective() {
      var modif = window.scrollY / window.innerHeight / 9;
      this.ring.rotation.x = -Math.PI / 2 + modif;
    },
    animateScene() {
      const animate = () => {
        this.showTitles = this.divText.clientHeight <= 1;

        this.rotationAngle += this.rotationSpeed;

        this.sphere.rotation.y = this.rotationAngle;
        // this.ring.rotation.x = this.rotationAngle;
        this.setRingPerspective();
        this.setSceneParameters();
        requestAnimationFrame(animate);
      };

      animate();
    },
    setMaxHeightText() {
      this.divText = document.getElementById("globe-text");
      this.maxHeightText = this.divText.clientHeight;
    },
  },
  created: function () {
    this.showVideo = this.$common.getBrowser() != "firefox";

    this.getScreenSize();
    window.addEventListener("resize", this.getScreenSize);
  },
  mounted: function () {
    this.handleResizeGlobe({
      width: document.getElementById("col-globe-div").clientWidth,
      height: document.getElementById("col-globe-div").clientHeight,
    });
    this.setMaxHeightText();
    this.createScene();
    this.create3DSphere();
    this.create3DRing();
    this.animateScene();

    window.addEventListener("scroll", this.computeShowChevron);

    this.isRenderInterval = setInterval(() => {
      this.checkIfRender();
    }, 50);
  },
  destroyed() {
    window.removeEventListener("resize", this.getScreenSize);
    window.removeEventListener("scroll", this.computeShowChevron);
  },
};
</script>
