<template>
  <div class="menu position-fixed z-index-3 d-flex d-md-block">
    <menu-object
      v-if="$authentification.user == null"
      class="my-md-3"
      label="Rejoindre le Process"
      :image="require('@/assets/images/icons/icon_join.png')"
      image-alt="Icon join"
      @nav-click="$router.push('/payment')"
    ></menu-object>
    <menu-object
      v-else
      class="my-md-3"
      label="Sphère Le Process"
      :image="require('@/assets/images/icons/icon_planet.png')"
      image-alt="Icon planet"
      @nav-click="$router.push('/')"
    ></menu-object>

    <menu-object
      v-if="$authentification.user == null"
      class="my-md-3"
      label="Plus d'informations"
      :image="require('@/assets/images/icons/icon_info.png')"
      image-alt="Icon info"
      @nav-click="clickOnInformations"
    ></menu-object>
    <menu-object
      v-else
      class="my-md-3"
      label="Reprendre"
      :image="require('@/assets/images/icons/icon_play.png')"
      image-alt="Icon play"
      @nav-click="$common.goToLastPart()"
    ></menu-object>

    <menu-object
      v-if="$authentification.user == null"
      class="my-md-3"
      label="Se connecter"
      :image="require('@/assets/images/icons/icon_login.png')"
      image-alt="Icon login"
      @nav-click="$common.showLoginModal()"
    ></menu-object>
    <menu-object
      v-else
      class="my-md-3"
      label="Tableau de bord"
      :image="require('@/assets/images/icons/icon_dashboard.png')"
      image-alt="Icon dashboard"
      @nav-click="$router.push('/dashboard')"
    ></menu-object>
  </div>
</template>

<script>
export default {
  name: "home-menu",
  data() {
    return {};
  },
  methods: {
    clickOnInformations() {
      if (this.$route.name == "Home") this.$eventHub.$emit("showInformations");
      else this.$router.push("/");
    },
  },
  created: function () {},
  mounted: function () {},
};
</script>
