import { auth } from './firebase'

export default {
    install(Vue) {
        var authentification = {
            user: null,
            async login(email, password) {
                await auth.signInWithEmailAndPassword(email, password);
            },
            async logout() {
                await auth.signOut();
            },
        }

        Vue.prototype.$authentification = authentification;
    }
}