import { firestore } from './firebase'

export default {
    install(Vue) {
        var database = {
            advancement: null,
            lastPartId: null,
            userId: null,
            async initAdvancement(userId) {
                this.userId = userId;
                var doc = await firestore.collection("advancements")
                    .doc(this.userId)
                    .get();

                this.advancement = doc.data();
            },
            async markPartAsValidated(partId) {
                var newValues = {};
                newValues[partId] = true;
                this.advancement[partId] = true;
                await firestore.collection("advancements")
                    .doc(this.userId)
                    .update(newValues);
            },
            async setLastPartId(partId) {
                if (partId == this.lastPartId) return;
                
                this.lastPartId = partId;
                await firestore.collection("advancements")
                    .doc(this.userId)
                    .update({
                        last_part_id: partId
                    });
            },
            checkPartValidated(partId) {
                return this.advancement.hasOwnProperty(partId) && this.advancement[partId];
            },
        }

        Vue.prototype.$database = database;
    }
}