import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDpTlJ7eR-6e0ny4BircgPVPOc8eJk2-j0",
    authDomain: "le-process.firebaseapp.com",
    projectId: "le-process",
    storageBucket: "le-process.appspot.com",
    messagingSenderId: "203230949757",
    appId: "1:203230949757:web:7a8af81a5b80d543131c14",
    measurementId: "G-JT9LJ62XG3"
};
firebase.initializeApp(firebaseConfig);

const analytics = firebase.analytics();
const auth = firebase.auth();
const firestore = firebase.firestore();
if (location.hostname === "localhost") {
    firestore.useEmulator("localhost", 8081);
}

export {
    analytics,
    auth,
    firestore,
}